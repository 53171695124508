import React from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { Div, SPACE } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import Footer from '$gcomponents/widgets/footer';

import { dialog, Table } from '$gcomponents/reusables';

import { REFUND_DETAILS_TABLE, REFUND_DETAILS_TABLE_CONFIG } from './refundDetailsTable';
import { Flex } from '$gstyles/wrapper';
import OrderSummary from '$fcomponents/orderSummary';
import CurrentStateModel from '$fbusiness/models/currentState';
import RefundModel from '$fbusiness/models/refund';
import InvoicePaymentSummary from '$fcomponents/orderSummary/invoicePaymentSummary';
import { isAccessible } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';

interface RefundDetailsProps {
  currentState: CurrentStateModel;
  refund: RefundModel;
  onApplyRefund: Function;
  onClose: () => void;
}

const RefundDetails: React.FC<RefundDetailsProps> = ({ onApplyRefund, currentState, refund, onClose }) => {
  if (!refund) return null;
  const { items, note, id } = refund;

  const { hidePrice } = currentState;

  const itemsTable = REFUND_DETAILS_TABLE();
  const tableProps = {
    styles: REFUND_DETAILS_TABLE_CONFIG.styles,
    TABLE: itemsTable,
    data: items,
  };

  const onApplyBalance = () => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.APPLY_REFUND_WARNING',
      onPress: async () => {
        await onApplyRefund(id);
        onClose();
      },
    });
  };

  return (
    <>
      <IonContent>
        <Div className="content-wrapper">
          <Table {...tableProps} />
          {isAccessible(ACCESS.MONEY.VIEW, currentState) && (
            <Flex justifyContent="flex-end">
              <Div width="300px">
                <OrderSummary isHidden={hidePrice} price={refund} />
                <InvoicePaymentSummary invoicePayment={refund} />
              </Div>
            </Flex>
          )}
          <Div padding={SPACE.LARGE}>
            <div className="notes-label">{intl('SCREEN.REFUNDS.COLS.NOTE')}</div>
            <textarea className="notes disabled" defaultValue={note} />
          </Div>
        </Div>
      </IonContent>
      <Footer justifyContent="space-around">
        <Button variant="outlined" className="w25 no-print" onClick={onClose}>
          {intl('BUTTON.CLOSE')}
        </Button>
        <Button variant="outlined" className="w25 no-print" onClick={window.print}>
          {intl('BUTTON.PRINT')}
        </Button>
        {!refund.isCredit && !refund.refundedAt && (
          <Button color="warning" className="third no-print" onClick={onApplyBalance}>
            {intl('SCREEN.REFUNDS.BUTTON_APPLY')}
          </Button>
        )}
      </Footer>
    </>
  );
};

export default RefundDetails;
